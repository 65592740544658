/* tslint:disable */
/* eslint-disable */
/**
 * API Documentation
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface Asset
 */
export interface Asset {
    /**
     * 
     * @type {string}
     * @memberof Asset
     */
    location?: string;
    /**
     * 
     * @type {string}
     * @memberof Asset
     */
    mediaType?: string;
    /**
     * 
     * @type {string}
     * @memberof Asset
     */
    assetHash?: string;
    /**
     * 
     * @type {boolean}
     * @memberof Asset
     */
    isHighlighted?: boolean;
    /**
     * 
     * @type {string}
     * @memberof Asset
     */
    title?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof Asset
     */
    authorCodes?: Array<string>;
}

/**
 * Check if a given object implements the Asset interface.
 */
export function instanceOfAsset(value: object): value is Asset {
    return true;
}

export function AssetFromJSON(json: any): Asset {
    return AssetFromJSONTyped(json, false);
}

export function AssetFromJSONTyped(json: any, ignoreDiscriminator: boolean): Asset {
    if (json == null) {
        return json;
    }
    return {
        
        'location': json['location'] == null ? undefined : json['location'],
        'mediaType': json['media_type'] == null ? undefined : json['media_type'],
        'assetHash': json['asset_hash'] == null ? undefined : json['asset_hash'],
        'isHighlighted': json['is_highlighted'] == null ? undefined : json['is_highlighted'],
        'title': json['title'] == null ? undefined : json['title'],
        'authorCodes': json['author_codes'] == null ? undefined : json['author_codes'],
    };
}

  export function AssetToJSON(json: any): Asset {
      return AssetToJSONTyped(json, false);
  }

  export function AssetToJSONTyped(value?: Asset | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'location': value['location'],
        'media_type': value['mediaType'],
        'asset_hash': value['assetHash'],
        'is_highlighted': value['isHighlighted'],
        'title': value['title'],
        'author_codes': value['authorCodes'],
    };
}

