// src/components/Modal.tsx
import React, { useEffect, useState } from 'react';
import ApiService from '../services/ApiService';
import { Person, Asset } from 'common-lib/generated-client';
import SocialMediaList from './SocialMediaList';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import './PersonDetailsModal.css';
import { CustomLeftArrow, CustomRightArrow } from 'components-lib';
import FollowExplanationModal from './FollowExplanationModal';

interface ModalProps {
  person: (Person & { followersCount?: number }) | null;
  onClose: () => void;
}

const PersonDetailsModal: React.FC<ModalProps> = ({ person, onClose }) => {
  const [assets, setAssets] = useState<Asset[]>([]);
  const [displayFollowExplanation, setDisplayFollowExplanation] = useState(false);
  const [isFollowing, setIsFollowing] = useState(false);

  useEffect(() => {
    if (!person) {
      return;
    }
    const fetchAssets = async (authorCode: string) => {
      try {
        const response = await ApiService.getAssets(authorCode);
        setAssets(response.assets ?? []);
      } catch (error) {
        console.error('Error fetching assets:', error);
      }
    };
    if (person?.authorCode) fetchAssets(person.authorCode);
  }, [person]);
  if (!person) {
    return null;
  }
  const getResponsiveCarouselSetup = (itemCount: number) => {
    return {
      tablet: {
        breakpoint: { max: 3000, min: 600 },
        items: Math.min(2, itemCount ?? 1),
      },
      mobile: {
        breakpoint: { max: 600, min: 0 },
        items: 1,
      },
    };
  };
  const onFollow = async () => {
    setDisplayFollowExplanation(true);
  };
  return (
    <div className="top-space">
      <div className="modal-overlay" onClick={onClose}>
        <div className="close-button" onClick={onClose}></div>
        <div className="modal-content" onClick={(e) => e.stopPropagation()}>
          <div className="profile-container">
            <img
              src={ApiService.getPersonImageURL(person)}
              alt={`${person.firstName} ${person.lastName}`}
              className="profile-picture"
            />
            <div className="profile-details">
              <h1>
                {person.firstName} {person.lastName}
              </h1>
              <p className="suremark-code">
                {person.vanityTag && person.vanityTag !== person.authorCode ? (
                  <>
                    /S/{person.vanityTag} (/S/{person.authorCode})
                  </>
                ) : (
                  <>/S/{person.authorCode}</>
                )}
              </p>
              <SocialMediaList person={person} />
              {person.followersCount ? (
                <p className="followers-count">
                  {person.followersCount} {person.followersCount === 1 ? 'follower' : 'followers'}
                </p>
              ) : undefined}
              <button
                className="button follow-button"
                data-author-code={person.authorCode}
                disabled={isFollowing}
                onClick={onFollow}
              >
                {isFollowing ? 'Following' : 'Follow'}
              </button>
            </div>
          </div>
          {assets.length ? (
            <>
              <div className="carousel-container">
                <Carousel
                  responsive={getResponsiveCarouselSetup(assets.length)}
                  customLeftArrow={<CustomLeftArrow />}
                  customRightArrow={<CustomRightArrow />}
                >
                  {assets.map((asset, i) => (
                    <div
                      key={i}
                      className={`carousel-item ${asset.isHighlighted ? 'highlighted-asset' : ''}`}
                    >
                      <p className="video-title">
                        {asset.authorCodes?.map((c) => `/S/${c}`).join(', ')}
                      </p>
                      {asset.mediaType?.startsWith('video/') ? (
                        <video controls className="fixed-width-visual" preload="metadata">
                          <source src={asset.location} type={asset.mediaType} />
                        </video>
                      ) : (
                        <a
                          href={asset.location}
                          target="_blank"
                          rel="noreferrer"
                          className="image-container"
                        >
                          <img
                            className="fixed-width-visual"
                            src={`${process.env.REACT_APP_API_BASE_URL}/api/assets/${asset.assetHash}/thumbnail`}
                            alt={`Thumbnail for ${asset.assetHash}`}
                          />{' '}
                        </a>
                      )}
                      <p className="video-title">{asset.title}</p>
                    </div>
                  ))}
                </Carousel>
              </div>
            </>
          ) : null}
        </div>
      </div>
      {/* Conditionally render FollowExplanationModal */}
      {displayFollowExplanation && (
        <FollowExplanationModal onClose={() => setDisplayFollowExplanation(false)} />
      )}
    </div>
  );
};

export default PersonDetailsModal;
