/* tslint:disable */
/* eslint-disable */
/**
 * API Documentation
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ApiMycodePostRequest
 */
export interface ApiMycodePostRequest {
    /**
     * Name to save.
     * @type {string}
     * @memberof ApiMycodePostRequest
     */
    name?: string;
}

/**
 * Check if a given object implements the ApiMycodePostRequest interface.
 */
export function instanceOfApiMycodePostRequest(value: object): value is ApiMycodePostRequest {
    return true;
}

export function ApiMycodePostRequestFromJSON(json: any): ApiMycodePostRequest {
    return ApiMycodePostRequestFromJSONTyped(json, false);
}

export function ApiMycodePostRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): ApiMycodePostRequest {
    if (json == null) {
        return json;
    }
    return {
        
        'name': json['name'] == null ? undefined : json['name'],
    };
}

  export function ApiMycodePostRequestToJSON(json: any): ApiMycodePostRequest {
      return ApiMycodePostRequestToJSONTyped(json, false);
  }

  export function ApiMycodePostRequestToJSONTyped(value?: ApiMycodePostRequest | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'name': value['name'],
    };
}

