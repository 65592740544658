/* tslint:disable */
/* eslint-disable */
/**
 * API Documentation
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { ApiMySignatureRequestsGet200ResponseSignatureRequestsInnerOtherAuthorsInner } from './ApiMySignatureRequestsGet200ResponseSignatureRequestsInnerOtherAuthorsInner';
import {
    ApiMySignatureRequestsGet200ResponseSignatureRequestsInnerOtherAuthorsInnerFromJSON,
    ApiMySignatureRequestsGet200ResponseSignatureRequestsInnerOtherAuthorsInnerFromJSONTyped,
    ApiMySignatureRequestsGet200ResponseSignatureRequestsInnerOtherAuthorsInnerToJSON,
    ApiMySignatureRequestsGet200ResponseSignatureRequestsInnerOtherAuthorsInnerToJSONTyped,
} from './ApiMySignatureRequestsGet200ResponseSignatureRequestsInnerOtherAuthorsInner';

/**
 * 
 * @export
 * @interface ApiMySignatureRequestsGet200ResponseSignatureRequestsInner
 */
export interface ApiMySignatureRequestsGet200ResponseSignatureRequestsInner {
    /**
     * The unique identifier for the signature request
     * @type {string}
     * @memberof ApiMySignatureRequestsGet200ResponseSignatureRequestsInner
     */
    id?: string;
    /**
     * The hash of the asset to be signed
     * @type {string}
     * @memberof ApiMySignatureRequestsGet200ResponseSignatureRequestsInner
     */
    assetHash?: string;
    /**
     * The URL of the asset
     * @type {string}
     * @memberof ApiMySignatureRequestsGet200ResponseSignatureRequestsInner
     */
    url?: string;
    /**
     * The URL of the asset to browse
     * @type {string}
     * @memberof ApiMySignatureRequestsGet200ResponseSignatureRequestsInner
     */
    browsableUrl?: string;
    /**
     * Indicates if the asset has been signed
     * @type {boolean}
     * @memberof ApiMySignatureRequestsGet200ResponseSignatureRequestsInner
     */
    isSigned?: boolean;
    /**
     * Indicates if the current user is the owner of the asset
     * @type {boolean}
     * @memberof ApiMySignatureRequestsGet200ResponseSignatureRequestsInner
     */
    isOwner?: boolean;
    /**
     * Indicates if the asset is pinned
     * @type {boolean}
     * @memberof ApiMySignatureRequestsGet200ResponseSignatureRequestsInner
     */
    isPinned?: boolean;
    /**
     * Indicates if the asset is highlighted
     * @type {boolean}
     * @memberof ApiMySignatureRequestsGet200ResponseSignatureRequestsInner
     */
    isHighlighted?: boolean;
    /**
     * Indicates if the asset can be pinned
     * @type {boolean}
     * @memberof ApiMySignatureRequestsGet200ResponseSignatureRequestsInner
     */
    isPinnable?: boolean;
    /**
     * The logical group to which the asset belongs, e.g., tweet ID
     * @type {string}
     * @memberof ApiMySignatureRequestsGet200ResponseSignatureRequestsInner
     */
    group?: string | null;
    /**
     * The location of the asset
     * @type {string}
     * @memberof ApiMySignatureRequestsGet200ResponseSignatureRequestsInner
     */
    location?: string;
    /**
     * 
     * @type {Array<ApiMySignatureRequestsGet200ResponseSignatureRequestsInnerOtherAuthorsInner>}
     * @memberof ApiMySignatureRequestsGet200ResponseSignatureRequestsInner
     */
    otherAuthors?: Array<ApiMySignatureRequestsGet200ResponseSignatureRequestsInnerOtherAuthorsInner>;
}

/**
 * Check if a given object implements the ApiMySignatureRequestsGet200ResponseSignatureRequestsInner interface.
 */
export function instanceOfApiMySignatureRequestsGet200ResponseSignatureRequestsInner(value: object): value is ApiMySignatureRequestsGet200ResponseSignatureRequestsInner {
    return true;
}

export function ApiMySignatureRequestsGet200ResponseSignatureRequestsInnerFromJSON(json: any): ApiMySignatureRequestsGet200ResponseSignatureRequestsInner {
    return ApiMySignatureRequestsGet200ResponseSignatureRequestsInnerFromJSONTyped(json, false);
}

export function ApiMySignatureRequestsGet200ResponseSignatureRequestsInnerFromJSONTyped(json: any, ignoreDiscriminator: boolean): ApiMySignatureRequestsGet200ResponseSignatureRequestsInner {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'] == null ? undefined : json['id'],
        'assetHash': json['asset_hash'] == null ? undefined : json['asset_hash'],
        'url': json['url'] == null ? undefined : json['url'],
        'browsableUrl': json['browsable_url'] == null ? undefined : json['browsable_url'],
        'isSigned': json['is_signed'] == null ? undefined : json['is_signed'],
        'isOwner': json['is_owner'] == null ? undefined : json['is_owner'],
        'isPinned': json['is_pinned'] == null ? undefined : json['is_pinned'],
        'isHighlighted': json['is_highlighted'] == null ? undefined : json['is_highlighted'],
        'isPinnable': json['is_pinnable'] == null ? undefined : json['is_pinnable'],
        'group': json['group'] == null ? undefined : json['group'],
        'location': json['location'] == null ? undefined : json['location'],
        'otherAuthors': json['other_authors'] == null ? undefined : ((json['other_authors'] as Array<any>).map(ApiMySignatureRequestsGet200ResponseSignatureRequestsInnerOtherAuthorsInnerFromJSON)),
    };
}

  export function ApiMySignatureRequestsGet200ResponseSignatureRequestsInnerToJSON(json: any): ApiMySignatureRequestsGet200ResponseSignatureRequestsInner {
      return ApiMySignatureRequestsGet200ResponseSignatureRequestsInnerToJSONTyped(json, false);
  }

  export function ApiMySignatureRequestsGet200ResponseSignatureRequestsInnerToJSONTyped(value?: ApiMySignatureRequestsGet200ResponseSignatureRequestsInner | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'id': value['id'],
        'asset_hash': value['assetHash'],
        'url': value['url'],
        'browsable_url': value['browsableUrl'],
        'is_signed': value['isSigned'],
        'is_owner': value['isOwner'],
        'is_pinned': value['isPinned'],
        'is_highlighted': value['isHighlighted'],
        'is_pinnable': value['isPinnable'],
        'group': value['group'],
        'location': value['location'],
        'other_authors': value['otherAuthors'] == null ? undefined : ((value['otherAuthors'] as Array<any>).map(ApiMySignatureRequestsGet200ResponseSignatureRequestsInnerOtherAuthorsInnerToJSON)),
    };
}

