/* tslint:disable */
/* eslint-disable */
/**
 * API Documentation
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ApiMySignatureRequestsGet200ResponseSignatureRequestsInnerOtherAuthorsInner
 */
export interface ApiMySignatureRequestsGet200ResponseSignatureRequestsInnerOtherAuthorsInner {
    /**
     * 
     * @type {boolean}
     * @memberof ApiMySignatureRequestsGet200ResponseSignatureRequestsInnerOtherAuthorsInner
     */
    isByEmail?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ApiMySignatureRequestsGet200ResponseSignatureRequestsInnerOtherAuthorsInner
     */
    authorCode?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ApiMySignatureRequestsGet200ResponseSignatureRequestsInnerOtherAuthorsInner
     */
    firstName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ApiMySignatureRequestsGet200ResponseSignatureRequestsInnerOtherAuthorsInner
     */
    lastName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ApiMySignatureRequestsGet200ResponseSignatureRequestsInnerOtherAuthorsInner
     */
    email?: string | null;
}

/**
 * Check if a given object implements the ApiMySignatureRequestsGet200ResponseSignatureRequestsInnerOtherAuthorsInner interface.
 */
export function instanceOfApiMySignatureRequestsGet200ResponseSignatureRequestsInnerOtherAuthorsInner(value: object): value is ApiMySignatureRequestsGet200ResponseSignatureRequestsInnerOtherAuthorsInner {
    return true;
}

export function ApiMySignatureRequestsGet200ResponseSignatureRequestsInnerOtherAuthorsInnerFromJSON(json: any): ApiMySignatureRequestsGet200ResponseSignatureRequestsInnerOtherAuthorsInner {
    return ApiMySignatureRequestsGet200ResponseSignatureRequestsInnerOtherAuthorsInnerFromJSONTyped(json, false);
}

export function ApiMySignatureRequestsGet200ResponseSignatureRequestsInnerOtherAuthorsInnerFromJSONTyped(json: any, ignoreDiscriminator: boolean): ApiMySignatureRequestsGet200ResponseSignatureRequestsInnerOtherAuthorsInner {
    if (json == null) {
        return json;
    }
    return {
        
        'isByEmail': json['is_by_email'] == null ? undefined : json['is_by_email'],
        'authorCode': json['author_code'] == null ? undefined : json['author_code'],
        'firstName': json['first_name'] == null ? undefined : json['first_name'],
        'lastName': json['last_name'] == null ? undefined : json['last_name'],
        'email': json['email'] == null ? undefined : json['email'],
    };
}

  export function ApiMySignatureRequestsGet200ResponseSignatureRequestsInnerOtherAuthorsInnerToJSON(json: any): ApiMySignatureRequestsGet200ResponseSignatureRequestsInnerOtherAuthorsInner {
      return ApiMySignatureRequestsGet200ResponseSignatureRequestsInnerOtherAuthorsInnerToJSONTyped(json, false);
  }

  export function ApiMySignatureRequestsGet200ResponseSignatureRequestsInnerOtherAuthorsInnerToJSONTyped(value?: ApiMySignatureRequestsGet200ResponseSignatureRequestsInnerOtherAuthorsInner | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'is_by_email': value['isByEmail'],
        'author_code': value['authorCode'],
        'first_name': value['firstName'],
        'last_name': value['lastName'],
        'email': value['email'],
    };
}

