/* tslint:disable */
/* eslint-disable */
/**
 * API Documentation
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ApiAssetsHashHighlightPostRequest
 */
export interface ApiAssetsHashHighlightPostRequest {
    /**
     * Whether to highlight (true) or un-highlight (false) the asset
     * @type {boolean}
     * @memberof ApiAssetsHashHighlightPostRequest
     */
    highlight: boolean;
}

/**
 * Check if a given object implements the ApiAssetsHashHighlightPostRequest interface.
 */
export function instanceOfApiAssetsHashHighlightPostRequest(value: object): value is ApiAssetsHashHighlightPostRequest {
    if (!('highlight' in value) || value['highlight'] === undefined) return false;
    return true;
}

export function ApiAssetsHashHighlightPostRequestFromJSON(json: any): ApiAssetsHashHighlightPostRequest {
    return ApiAssetsHashHighlightPostRequestFromJSONTyped(json, false);
}

export function ApiAssetsHashHighlightPostRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): ApiAssetsHashHighlightPostRequest {
    if (json == null) {
        return json;
    }
    return {
        
        'highlight': json['highlight'],
    };
}

  export function ApiAssetsHashHighlightPostRequestToJSON(json: any): ApiAssetsHashHighlightPostRequest {
      return ApiAssetsHashHighlightPostRequestToJSONTyped(json, false);
  }

  export function ApiAssetsHashHighlightPostRequestToJSONTyped(value?: ApiAssetsHashHighlightPostRequest | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'highlight': value['highlight'],
    };
}

