/* tslint:disable */
/* eslint-disable */
/**
 * API Documentation
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { Asset } from './Asset';
import {
    AssetFromJSON,
    AssetFromJSONTyped,
    AssetToJSON,
    AssetToJSONTyped,
} from './Asset';

/**
 * 
 * @export
 * @interface ApiPeopleCodeAssetsGet200Response
 */
export interface ApiPeopleCodeAssetsGet200Response {
    /**
     * 
     * @type {Array<Asset>}
     * @memberof ApiPeopleCodeAssetsGet200Response
     */
    assets?: Array<Asset>;
}

/**
 * Check if a given object implements the ApiPeopleCodeAssetsGet200Response interface.
 */
export function instanceOfApiPeopleCodeAssetsGet200Response(value: object): value is ApiPeopleCodeAssetsGet200Response {
    return true;
}

export function ApiPeopleCodeAssetsGet200ResponseFromJSON(json: any): ApiPeopleCodeAssetsGet200Response {
    return ApiPeopleCodeAssetsGet200ResponseFromJSONTyped(json, false);
}

export function ApiPeopleCodeAssetsGet200ResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): ApiPeopleCodeAssetsGet200Response {
    if (json == null) {
        return json;
    }
    return {
        
        'assets': json['assets'] == null ? undefined : ((json['assets'] as Array<any>).map(AssetFromJSON)),
    };
}

  export function ApiPeopleCodeAssetsGet200ResponseToJSON(json: any): ApiPeopleCodeAssetsGet200Response {
      return ApiPeopleCodeAssetsGet200ResponseToJSONTyped(json, false);
  }

  export function ApiPeopleCodeAssetsGet200ResponseToJSONTyped(value?: ApiPeopleCodeAssetsGet200Response | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'assets': value['assets'] == null ? undefined : ((value['assets'] as Array<any>).map(AssetToJSON)),
    };
}

