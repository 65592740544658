/* tslint:disable */
/* eslint-disable */
/**
 * API Documentation
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  ApiAssetsExternalExternalIdGet200Response,
  ApiAssetsExternalExternalIdGet404Response,
  ApiAssetsHashClaimsGet200Response,
  ApiAssetsHashHighlightPost200Response,
  ApiAssetsHashHighlightPost400Response,
  ApiAssetsHashHighlightPostRequest,
  ApiAssetsHashInvitationsPost201Response,
  ApiAssetsHashInvitationsPost400Response,
  ApiAssetsHashInvitationsPost404Response,
  ApiAssetsHashInvitationsPostRequest,
  ApiAssetsHashPinPost200Response,
  ApiAssetsHashPinPost400Response,
  ApiAssetsHashPinPost404Response,
  ApiAssetsHashPinPost500Response,
  ApiAssetsHashPinPostRequest,
  ApiAssetsHashSignaturesGet200Response,
  ApiAssetsYoutubeExternalIdGet200Response,
  ApiFollowingPost200Response,
  ApiFollowingPostRequest,
  ApiInstagramEntryTypeIdSignaturesGet200ResponseInner,
  ApiMySignatureRequestsGet200Response,
  ApiMySignatureRequestsSignPost200Response,
  ApiMySignatureRequestsSignPostRequest,
  ApiMycodeGet200Response,
  ApiMycodeGet401Response,
  ApiMycodePost200Response,
  ApiMycodePostRequest,
  ApiMyimagePost200Response,
  ApiMyimagePostRequest,
  ApiMykeyGet200Response,
  ApiMykeyGet401Response,
  ApiMykeyPostRequest,
  ApiPeopleCodeAssetsGet200Response,
  ApiPeopleCodeGet200Response,
  ApiPeopleCodeGet404Response,
  ApiPeopleCodePublicKeysGet200Response,
  ApiPeopleCodePublicKeysGet400Response,
  ApiPeopleCodePublicKeysGet500Response,
  ApiPeopleCodeSocialMediaGet200Response,
  ApiPeopleFirstLettersGet200Response,
  ApiPeopleGet200Response,
  ApiPostPost200Response,
  ApiPostPostRequest,
  ApiSendOtpPostRequest,
  ApiTweetsIdSignaturesGet200ResponseInner,
  ApiVerifyOtpPost200Response,
  ApiVerifyOtpPostRequest,
  ApiYoutubePost200Response,
  ApiYoutubePostRequest,
  AuthVerifyTokenGet200Response,
  AuthVerifyTokenGet401Response,
} from '../models/index';
import {
    ApiAssetsExternalExternalIdGet200ResponseFromJSON,
    ApiAssetsExternalExternalIdGet200ResponseToJSON,
    ApiAssetsExternalExternalIdGet404ResponseFromJSON,
    ApiAssetsExternalExternalIdGet404ResponseToJSON,
    ApiAssetsHashClaimsGet200ResponseFromJSON,
    ApiAssetsHashClaimsGet200ResponseToJSON,
    ApiAssetsHashHighlightPost200ResponseFromJSON,
    ApiAssetsHashHighlightPost200ResponseToJSON,
    ApiAssetsHashHighlightPost400ResponseFromJSON,
    ApiAssetsHashHighlightPost400ResponseToJSON,
    ApiAssetsHashHighlightPostRequestFromJSON,
    ApiAssetsHashHighlightPostRequestToJSON,
    ApiAssetsHashInvitationsPost201ResponseFromJSON,
    ApiAssetsHashInvitationsPost201ResponseToJSON,
    ApiAssetsHashInvitationsPost400ResponseFromJSON,
    ApiAssetsHashInvitationsPost400ResponseToJSON,
    ApiAssetsHashInvitationsPost404ResponseFromJSON,
    ApiAssetsHashInvitationsPost404ResponseToJSON,
    ApiAssetsHashInvitationsPostRequestFromJSON,
    ApiAssetsHashInvitationsPostRequestToJSON,
    ApiAssetsHashPinPost200ResponseFromJSON,
    ApiAssetsHashPinPost200ResponseToJSON,
    ApiAssetsHashPinPost400ResponseFromJSON,
    ApiAssetsHashPinPost400ResponseToJSON,
    ApiAssetsHashPinPost404ResponseFromJSON,
    ApiAssetsHashPinPost404ResponseToJSON,
    ApiAssetsHashPinPost500ResponseFromJSON,
    ApiAssetsHashPinPost500ResponseToJSON,
    ApiAssetsHashPinPostRequestFromJSON,
    ApiAssetsHashPinPostRequestToJSON,
    ApiAssetsHashSignaturesGet200ResponseFromJSON,
    ApiAssetsHashSignaturesGet200ResponseToJSON,
    ApiAssetsYoutubeExternalIdGet200ResponseFromJSON,
    ApiAssetsYoutubeExternalIdGet200ResponseToJSON,
    ApiFollowingPost200ResponseFromJSON,
    ApiFollowingPost200ResponseToJSON,
    ApiFollowingPostRequestFromJSON,
    ApiFollowingPostRequestToJSON,
    ApiInstagramEntryTypeIdSignaturesGet200ResponseInnerFromJSON,
    ApiInstagramEntryTypeIdSignaturesGet200ResponseInnerToJSON,
    ApiMySignatureRequestsGet200ResponseFromJSON,
    ApiMySignatureRequestsGet200ResponseToJSON,
    ApiMySignatureRequestsSignPost200ResponseFromJSON,
    ApiMySignatureRequestsSignPost200ResponseToJSON,
    ApiMySignatureRequestsSignPostRequestFromJSON,
    ApiMySignatureRequestsSignPostRequestToJSON,
    ApiMycodeGet200ResponseFromJSON,
    ApiMycodeGet200ResponseToJSON,
    ApiMycodeGet401ResponseFromJSON,
    ApiMycodeGet401ResponseToJSON,
    ApiMycodePost200ResponseFromJSON,
    ApiMycodePost200ResponseToJSON,
    ApiMycodePostRequestFromJSON,
    ApiMycodePostRequestToJSON,
    ApiMyimagePost200ResponseFromJSON,
    ApiMyimagePost200ResponseToJSON,
    ApiMyimagePostRequestFromJSON,
    ApiMyimagePostRequestToJSON,
    ApiMykeyGet200ResponseFromJSON,
    ApiMykeyGet200ResponseToJSON,
    ApiMykeyGet401ResponseFromJSON,
    ApiMykeyGet401ResponseToJSON,
    ApiMykeyPostRequestFromJSON,
    ApiMykeyPostRequestToJSON,
    ApiPeopleCodeAssetsGet200ResponseFromJSON,
    ApiPeopleCodeAssetsGet200ResponseToJSON,
    ApiPeopleCodeGet200ResponseFromJSON,
    ApiPeopleCodeGet200ResponseToJSON,
    ApiPeopleCodeGet404ResponseFromJSON,
    ApiPeopleCodeGet404ResponseToJSON,
    ApiPeopleCodePublicKeysGet200ResponseFromJSON,
    ApiPeopleCodePublicKeysGet200ResponseToJSON,
    ApiPeopleCodePublicKeysGet400ResponseFromJSON,
    ApiPeopleCodePublicKeysGet400ResponseToJSON,
    ApiPeopleCodePublicKeysGet500ResponseFromJSON,
    ApiPeopleCodePublicKeysGet500ResponseToJSON,
    ApiPeopleCodeSocialMediaGet200ResponseFromJSON,
    ApiPeopleCodeSocialMediaGet200ResponseToJSON,
    ApiPeopleFirstLettersGet200ResponseFromJSON,
    ApiPeopleFirstLettersGet200ResponseToJSON,
    ApiPeopleGet200ResponseFromJSON,
    ApiPeopleGet200ResponseToJSON,
    ApiPostPost200ResponseFromJSON,
    ApiPostPost200ResponseToJSON,
    ApiPostPostRequestFromJSON,
    ApiPostPostRequestToJSON,
    ApiSendOtpPostRequestFromJSON,
    ApiSendOtpPostRequestToJSON,
    ApiTweetsIdSignaturesGet200ResponseInnerFromJSON,
    ApiTweetsIdSignaturesGet200ResponseInnerToJSON,
    ApiVerifyOtpPost200ResponseFromJSON,
    ApiVerifyOtpPost200ResponseToJSON,
    ApiVerifyOtpPostRequestFromJSON,
    ApiVerifyOtpPostRequestToJSON,
    ApiYoutubePost200ResponseFromJSON,
    ApiYoutubePost200ResponseToJSON,
    ApiYoutubePostRequestFromJSON,
    ApiYoutubePostRequestToJSON,
    AuthVerifyTokenGet200ResponseFromJSON,
    AuthVerifyTokenGet200ResponseToJSON,
    AuthVerifyTokenGet401ResponseFromJSON,
    AuthVerifyTokenGet401ResponseToJSON,
} from '../models/index';

export interface ApiAssetsExternalExternalIdGetRequest {
    externalId: string;
}

export interface ApiAssetsHashClaimsGetRequest {
    hash: string;
}

export interface ApiAssetsHashHighlightPostOperationRequest {
    hash: string;
    apiAssetsHashHighlightPostRequest: ApiAssetsHashHighlightPostRequest;
}

export interface ApiAssetsHashInvitationsPostOperationRequest {
    hash: string;
    apiAssetsHashInvitationsPostRequest: ApiAssetsHashInvitationsPostRequest;
}

export interface ApiAssetsHashPinPostOperationRequest {
    hash: string;
    apiAssetsHashPinPostRequest: ApiAssetsHashPinPostRequest;
}

export interface ApiAssetsHashSignaturesGetRequest {
    hash: string;
}

export interface ApiAssetsYoutubeExternalIdGetRequest {
    externalId: string;
    ytType?: string;
}

export interface ApiFollowingPostOperationRequest {
    apiFollowingPostRequest: ApiFollowingPostRequest;
}

export interface ApiInstagramEntryTypeIdSignaturesGetRequest {
    entryType: string;
    id: string;
    refresh?: boolean;
}

export interface ApiMySignatureRequestsSignPostOperationRequest {
    apiMySignatureRequestsSignPostRequest: ApiMySignatureRequestsSignPostRequest;
}

export interface ApiMycodePostOperationRequest {
    apiMycodePostRequest: ApiMycodePostRequest;
}

export interface ApiMyimagePostOperationRequest {
    apiMyimagePostRequest: ApiMyimagePostRequest;
}

export interface ApiMykeyPostOperationRequest {
    apiMykeyPostRequest: ApiMykeyPostRequest;
}

export interface ApiPeopleCodeAssetsGetRequest {
    code: string;
}

export interface ApiPeopleCodeGetRequest {
    code: string;
}

export interface ApiPeopleCodeImageGetRequest {
    code: string;
}

export interface ApiPeopleCodePublicKeysGetRequest {
    code: string;
}

export interface ApiPeopleCodeSocialMediaGetRequest {
    code: string;
}

export interface ApiPeopleGetRequest {
    search?: string;
}

export interface ApiPostPostOperationRequest {
    apiPostPostRequest: ApiPostPostRequest;
}

export interface ApiSendOtpPostOperationRequest {
    apiSendOtpPostRequest: ApiSendOtpPostRequest;
}

export interface ApiTweetsIdSignaturesGetRequest {
    id: string;
}

export interface ApiVerifyOtpPostOperationRequest {
    apiVerifyOtpPostRequest: ApiVerifyOtpPostRequest;
}

export interface ApiYoutubePostOperationRequest {
    apiYoutubePostRequest: ApiYoutubePostRequest;
}

/**
 * 
 */
export class DefaultApi extends runtime.BaseAPI {

    /**
     * This endpoint retrieves the asset URL, hash and author codes associated with the specified external ID.
     * Get asset Info by external ID
     */
    async apiAssetsExternalExternalIdGetRaw(requestParameters: ApiAssetsExternalExternalIdGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ApiAssetsExternalExternalIdGet200Response>> {
        if (requestParameters['externalId'] == null) {
            throw new runtime.RequiredError(
                'externalId',
                'Required parameter "externalId" was null or undefined when calling apiAssetsExternalExternalIdGet().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/assets/external/{externalId}`.replace(`{${"externalId"}}`, encodeURIComponent(String(requestParameters['externalId']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ApiAssetsExternalExternalIdGet200ResponseFromJSON(jsonValue));
    }

    /**
     * This endpoint retrieves the asset URL, hash and author codes associated with the specified external ID.
     * Get asset Info by external ID
     */
    async apiAssetsExternalExternalIdGet(requestParameters: ApiAssetsExternalExternalIdGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ApiAssetsExternalExternalIdGet200Response> {
        const response = await this.apiAssetsExternalExternalIdGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * This endpoint retrieves the claims associated with the specified asset hash.
     * Get claims by asset hash
     */
    async apiAssetsHashClaimsGetRaw(requestParameters: ApiAssetsHashClaimsGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ApiAssetsHashClaimsGet200Response>> {
        if (requestParameters['hash'] == null) {
            throw new runtime.RequiredError(
                'hash',
                'Required parameter "hash" was null or undefined when calling apiAssetsHashClaimsGet().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/assets/{hash}/claims`.replace(`{${"hash"}}`, encodeURIComponent(String(requestParameters['hash']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ApiAssetsHashClaimsGet200ResponseFromJSON(jsonValue));
    }

    /**
     * This endpoint retrieves the claims associated with the specified asset hash.
     * Get claims by asset hash
     */
    async apiAssetsHashClaimsGet(requestParameters: ApiAssetsHashClaimsGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ApiAssetsHashClaimsGet200Response> {
        const response = await this.apiAssetsHashClaimsGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Updates the highlighted status for the authenticated user\'s signature request associated with the asset
     * Highlight or un-highlight an asset
     */
    async apiAssetsHashHighlightPostRaw(requestParameters: ApiAssetsHashHighlightPostOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ApiAssetsHashHighlightPost200Response>> {
        if (requestParameters['hash'] == null) {
            throw new runtime.RequiredError(
                'hash',
                'Required parameter "hash" was null or undefined when calling apiAssetsHashHighlightPost().'
            );
        }

        if (requestParameters['apiAssetsHashHighlightPostRequest'] == null) {
            throw new runtime.RequiredError(
                'apiAssetsHashHighlightPostRequest',
                'Required parameter "apiAssetsHashHighlightPostRequest" was null or undefined when calling apiAssetsHashHighlightPost().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/assets/{hash}/highlight`.replace(`{${"hash"}}`, encodeURIComponent(String(requestParameters['hash']))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ApiAssetsHashHighlightPostRequestToJSON(requestParameters['apiAssetsHashHighlightPostRequest']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ApiAssetsHashHighlightPost200ResponseFromJSON(jsonValue));
    }

    /**
     * Updates the highlighted status for the authenticated user\'s signature request associated with the asset
     * Highlight or un-highlight an asset
     */
    async apiAssetsHashHighlightPost(requestParameters: ApiAssetsHashHighlightPostOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ApiAssetsHashHighlightPost200Response> {
        const response = await this.apiAssetsHashHighlightPostRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Sends an invitation for a person (identified by author_code) to sign a specific asset.
     * Invite a person to sign an asset
     */
    async apiAssetsHashInvitationsPostRaw(requestParameters: ApiAssetsHashInvitationsPostOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ApiAssetsHashInvitationsPost201Response>> {
        if (requestParameters['hash'] == null) {
            throw new runtime.RequiredError(
                'hash',
                'Required parameter "hash" was null or undefined when calling apiAssetsHashInvitationsPost().'
            );
        }

        if (requestParameters['apiAssetsHashInvitationsPostRequest'] == null) {
            throw new runtime.RequiredError(
                'apiAssetsHashInvitationsPostRequest',
                'Required parameter "apiAssetsHashInvitationsPostRequest" was null or undefined when calling apiAssetsHashInvitationsPost().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/assets/{hash}/invitations`.replace(`{${"hash"}}`, encodeURIComponent(String(requestParameters['hash']))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ApiAssetsHashInvitationsPostRequestToJSON(requestParameters['apiAssetsHashInvitationsPostRequest']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ApiAssetsHashInvitationsPost201ResponseFromJSON(jsonValue));
    }

    /**
     * Sends an invitation for a person (identified by author_code) to sign a specific asset.
     * Invite a person to sign an asset
     */
    async apiAssetsHashInvitationsPost(requestParameters: ApiAssetsHashInvitationsPostOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ApiAssetsHashInvitationsPost201Response> {
        const response = await this.apiAssetsHashInvitationsPostRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Updates the pinned status for the authenticated user\'s signature request associated with the asset
     * Pin or unpin an asset
     */
    async apiAssetsHashPinPostRaw(requestParameters: ApiAssetsHashPinPostOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ApiAssetsHashPinPost200Response>> {
        if (requestParameters['hash'] == null) {
            throw new runtime.RequiredError(
                'hash',
                'Required parameter "hash" was null or undefined when calling apiAssetsHashPinPost().'
            );
        }

        if (requestParameters['apiAssetsHashPinPostRequest'] == null) {
            throw new runtime.RequiredError(
                'apiAssetsHashPinPostRequest',
                'Required parameter "apiAssetsHashPinPostRequest" was null or undefined when calling apiAssetsHashPinPost().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/assets/{hash}/pin`.replace(`{${"hash"}}`, encodeURIComponent(String(requestParameters['hash']))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ApiAssetsHashPinPostRequestToJSON(requestParameters['apiAssetsHashPinPostRequest']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ApiAssetsHashPinPost200ResponseFromJSON(jsonValue));
    }

    /**
     * Updates the pinned status for the authenticated user\'s signature request associated with the asset
     * Pin or unpin an asset
     */
    async apiAssetsHashPinPost(requestParameters: ApiAssetsHashPinPostOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ApiAssetsHashPinPost200Response> {
        const response = await this.apiAssetsHashPinPostRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * This endpoint retrieves the signatures associated with the specified asset hash.
     * Get signatures by asset hash
     */
    async apiAssetsHashSignaturesGetRaw(requestParameters: ApiAssetsHashSignaturesGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ApiAssetsHashSignaturesGet200Response>> {
        if (requestParameters['hash'] == null) {
            throw new runtime.RequiredError(
                'hash',
                'Required parameter "hash" was null or undefined when calling apiAssetsHashSignaturesGet().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/assets/{hash}/signatures`.replace(`{${"hash"}}`, encodeURIComponent(String(requestParameters['hash']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ApiAssetsHashSignaturesGet200ResponseFromJSON(jsonValue));
    }

    /**
     * This endpoint retrieves the signatures associated with the specified asset hash.
     * Get signatures by asset hash
     */
    async apiAssetsHashSignaturesGet(requestParameters: ApiAssetsHashSignaturesGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ApiAssetsHashSignaturesGet200Response> {
        const response = await this.apiAssetsHashSignaturesGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * This endpoint retrieves the asset URL, hash, and author codes associated with the specified external ID.
     * Get YouTube video info by ID
     */
    async apiAssetsYoutubeExternalIdGetRaw(requestParameters: ApiAssetsYoutubeExternalIdGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ApiAssetsYoutubeExternalIdGet200Response>> {
        if (requestParameters['externalId'] == null) {
            throw new runtime.RequiredError(
                'externalId',
                'Required parameter "externalId" was null or undefined when calling apiAssetsYoutubeExternalIdGet().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['ytType'] != null) {
            queryParameters['yt_type'] = requestParameters['ytType'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/assets/youtube/{externalId}`.replace(`{${"externalId"}}`, encodeURIComponent(String(requestParameters['externalId']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ApiAssetsYoutubeExternalIdGet200ResponseFromJSON(jsonValue));
    }

    /**
     * This endpoint retrieves the asset URL, hash, and author codes associated with the specified external ID.
     * Get YouTube video info by ID
     */
    async apiAssetsYoutubeExternalIdGet(requestParameters: ApiAssetsYoutubeExternalIdGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ApiAssetsYoutubeExternalIdGet200Response> {
        const response = await this.apiAssetsYoutubeExternalIdGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * This endpoint is used to save a list of followings, identified by an installation ID.
     * Save \"followings\"
     */
    async apiFollowingPostRaw(requestParameters: ApiFollowingPostOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ApiFollowingPost200Response>> {
        if (requestParameters['apiFollowingPostRequest'] == null) {
            throw new runtime.RequiredError(
                'apiFollowingPostRequest',
                'Required parameter "apiFollowingPostRequest" was null or undefined when calling apiFollowingPost().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/following`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ApiFollowingPostRequestToJSON(requestParameters['apiFollowingPostRequest']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ApiFollowingPost200ResponseFromJSON(jsonValue));
    }

    /**
     * This endpoint is used to save a list of followings, identified by an installation ID.
     * Save \"followings\"
     */
    async apiFollowingPost(requestParameters: ApiFollowingPostOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ApiFollowingPost200Response> {
        const response = await this.apiFollowingPostRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Retrieves assets and their signatures for a specified Instagram entry type and item ID.
     * Get signatures for assets of a specific Instagram entry
     */
    async apiInstagramEntryTypeIdSignaturesGetRaw(requestParameters: ApiInstagramEntryTypeIdSignaturesGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<ApiInstagramEntryTypeIdSignaturesGet200ResponseInner>>> {
        if (requestParameters['entryType'] == null) {
            throw new runtime.RequiredError(
                'entryType',
                'Required parameter "entryType" was null or undefined when calling apiInstagramEntryTypeIdSignaturesGet().'
            );
        }

        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling apiInstagramEntryTypeIdSignaturesGet().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['refresh'] != null) {
            queryParameters['refresh'] = requestParameters['refresh'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/instagram/{entryType}/{id}/signatures`.replace(`{${"entryType"}}`, encodeURIComponent(String(requestParameters['entryType']))).replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ApiInstagramEntryTypeIdSignaturesGet200ResponseInnerFromJSON));
    }

    /**
     * Retrieves assets and their signatures for a specified Instagram entry type and item ID.
     * Get signatures for assets of a specific Instagram entry
     */
    async apiInstagramEntryTypeIdSignaturesGet(requestParameters: ApiInstagramEntryTypeIdSignaturesGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<ApiInstagramEntryTypeIdSignaturesGet200ResponseInner>> {
        const response = await this.apiInstagramEntryTypeIdSignaturesGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Retrieve signature requests for the authenticated user
     */
    async apiMySignatureRequestsGetRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ApiMySignatureRequestsGet200Response>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/my_signature_requests`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ApiMySignatureRequestsGet200ResponseFromJSON(jsonValue));
    }

    /**
     * Retrieve signature requests for the authenticated user
     */
    async apiMySignatureRequestsGet(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ApiMySignatureRequestsGet200Response> {
        const response = await this.apiMySignatureRequestsGetRaw(initOverrides);
        return await response.value();
    }

    /**
     * Sign an asset for a specific signature request
     */
    async apiMySignatureRequestsSignPostRaw(requestParameters: ApiMySignatureRequestsSignPostOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ApiMySignatureRequestsSignPost200Response>> {
        if (requestParameters['apiMySignatureRequestsSignPostRequest'] == null) {
            throw new runtime.RequiredError(
                'apiMySignatureRequestsSignPostRequest',
                'Required parameter "apiMySignatureRequestsSignPostRequest" was null or undefined when calling apiMySignatureRequestsSignPost().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/my_signature_requests/sign`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ApiMySignatureRequestsSignPostRequestToJSON(requestParameters['apiMySignatureRequestsSignPostRequest']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ApiMySignatureRequestsSignPost200ResponseFromJSON(jsonValue));
    }

    /**
     * Sign an asset for a specific signature request
     */
    async apiMySignatureRequestsSignPost(requestParameters: ApiMySignatureRequestsSignPostOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ApiMySignatureRequestsSignPost200Response> {
        const response = await this.apiMySignatureRequestsSignPostRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * This endpoint retrieves the author code associated with the authenticated user.
     * Get author code
     */
    async apiMycodeGetRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ApiMycodeGet200Response>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/mycode`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ApiMycodeGet200ResponseFromJSON(jsonValue));
    }

    /**
     * This endpoint retrieves the author code associated with the authenticated user.
     * Get author code
     */
    async apiMycodeGet(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ApiMycodeGet200Response> {
        const response = await this.apiMycodeGetRaw(initOverrides);
        return await response.value();
    }

    /**
     * This endpoint accepts a name to save and returns an author code.
     * Generate an author code
     */
    async apiMycodePostRaw(requestParameters: ApiMycodePostOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ApiMycodePost200Response>> {
        if (requestParameters['apiMycodePostRequest'] == null) {
            throw new runtime.RequiredError(
                'apiMycodePostRequest',
                'Required parameter "apiMycodePostRequest" was null or undefined when calling apiMycodePost().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/mycode`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ApiMycodePostRequestToJSON(requestParameters['apiMycodePostRequest']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ApiMycodePost200ResponseFromJSON(jsonValue));
    }

    /**
     * This endpoint accepts a name to save and returns an author code.
     * Generate an author code
     */
    async apiMycodePost(requestParameters: ApiMycodePostOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ApiMycodePost200Response> {
        const response = await this.apiMycodePostRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Upload a photo in Base64 format
     */
    async apiMyimagePostRaw(requestParameters: ApiMyimagePostOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ApiMyimagePost200Response>> {
        if (requestParameters['apiMyimagePostRequest'] == null) {
            throw new runtime.RequiredError(
                'apiMyimagePostRequest',
                'Required parameter "apiMyimagePostRequest" was null or undefined when calling apiMyimagePost().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/myimage`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ApiMyimagePostRequestToJSON(requestParameters['apiMyimagePostRequest']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ApiMyimagePost200ResponseFromJSON(jsonValue));
    }

    /**
     * Upload a photo in Base64 format
     */
    async apiMyimagePost(requestParameters: ApiMyimagePostOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ApiMyimagePost200Response> {
        const response = await this.apiMyimagePostRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * This endpoint returns the secret key associated with the authenticated user.
     * Get secret key
     */
    async apiMykeyGetRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ApiMykeyGet200Response>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/mykey`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ApiMykeyGet200ResponseFromJSON(jsonValue));
    }

    /**
     * This endpoint returns the secret key associated with the authenticated user.
     * Get secret key
     */
    async apiMykeyGet(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ApiMykeyGet200Response> {
        const response = await this.apiMykeyGetRaw(initOverrides);
        return await response.value();
    }

    /**
     * This endpoint registers a secret key associated with the authenticated user\'s email.
     * Register secret key
     */
    async apiMykeyPostRaw(requestParameters: ApiMykeyPostOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['apiMykeyPostRequest'] == null) {
            throw new runtime.RequiredError(
                'apiMykeyPostRequest',
                'Required parameter "apiMykeyPostRequest" was null or undefined when calling apiMykeyPost().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/mykey`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ApiMykeyPostRequestToJSON(requestParameters['apiMykeyPostRequest']),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * This endpoint registers a secret key associated with the authenticated user\'s email.
     * Register secret key
     */
    async apiMykeyPost(requestParameters: ApiMykeyPostOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.apiMykeyPostRaw(requestParameters, initOverrides);
    }

    /**
     * This endpoint returns the pinned assets associated with a specific person code.
     * Get pinned assets by person code
     */
    async apiPeopleCodeAssetsGetRaw(requestParameters: ApiPeopleCodeAssetsGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ApiPeopleCodeAssetsGet200Response>> {
        if (requestParameters['code'] == null) {
            throw new runtime.RequiredError(
                'code',
                'Required parameter "code" was null or undefined when calling apiPeopleCodeAssetsGet().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/people/{code}/assets`.replace(`{${"code"}}`, encodeURIComponent(String(requestParameters['code']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ApiPeopleCodeAssetsGet200ResponseFromJSON(jsonValue));
    }

    /**
     * This endpoint returns the pinned assets associated with a specific person code.
     * Get pinned assets by person code
     */
    async apiPeopleCodeAssetsGet(requestParameters: ApiPeopleCodeAssetsGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ApiPeopleCodeAssetsGet200Response> {
        const response = await this.apiPeopleCodeAssetsGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * This endpoint returns a single person based on the provided unique code.
     * Get a person by their unique code
     */
    async apiPeopleCodeGetRaw(requestParameters: ApiPeopleCodeGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ApiPeopleCodeGet200Response>> {
        if (requestParameters['code'] == null) {
            throw new runtime.RequiredError(
                'code',
                'Required parameter "code" was null or undefined when calling apiPeopleCodeGet().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/people/{code}`.replace(`{${"code"}}`, encodeURIComponent(String(requestParameters['code']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ApiPeopleCodeGet200ResponseFromJSON(jsonValue));
    }

    /**
     * This endpoint returns a single person based on the provided unique code.
     * Get a person by their unique code
     */
    async apiPeopleCodeGet(requestParameters: ApiPeopleCodeGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ApiPeopleCodeGet200Response> {
        const response = await this.apiPeopleCodeGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * This endpoint returns the image associated with a specific person code.
     * Get image by person code
     */
    async apiPeopleCodeImageGetRaw(requestParameters: ApiPeopleCodeImageGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Blob>> {
        if (requestParameters['code'] == null) {
            throw new runtime.RequiredError(
                'code',
                'Required parameter "code" was null or undefined when calling apiPeopleCodeImageGet().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/people/{code}/image`.replace(`{${"code"}}`, encodeURIComponent(String(requestParameters['code']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.BlobApiResponse(response);
    }

    /**
     * This endpoint returns the image associated with a specific person code.
     * Get image by person code
     */
    async apiPeopleCodeImageGet(requestParameters: ApiPeopleCodeImageGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Blob> {
        const response = await this.apiPeopleCodeImageGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * This endpoint retrieves the public keys associated with the specified author code.
     * Get public keys by author code
     */
    async apiPeopleCodePublicKeysGetRaw(requestParameters: ApiPeopleCodePublicKeysGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ApiPeopleCodePublicKeysGet200Response>> {
        if (requestParameters['code'] == null) {
            throw new runtime.RequiredError(
                'code',
                'Required parameter "code" was null or undefined when calling apiPeopleCodePublicKeysGet().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/people/{code}/public_keys`.replace(`{${"code"}}`, encodeURIComponent(String(requestParameters['code']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ApiPeopleCodePublicKeysGet200ResponseFromJSON(jsonValue));
    }

    /**
     * This endpoint retrieves the public keys associated with the specified author code.
     * Get public keys by author code
     */
    async apiPeopleCodePublicKeysGet(requestParameters: ApiPeopleCodePublicKeysGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ApiPeopleCodePublicKeysGet200Response> {
        const response = await this.apiPeopleCodePublicKeysGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * This endpoint returns all social media associated with a specific person code.
     * Get social media accounts by person code
     */
    async apiPeopleCodeSocialMediaGetRaw(requestParameters: ApiPeopleCodeSocialMediaGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ApiPeopleCodeSocialMediaGet200Response>> {
        if (requestParameters['code'] == null) {
            throw new runtime.RequiredError(
                'code',
                'Required parameter "code" was null or undefined when calling apiPeopleCodeSocialMediaGet().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/people/{code}/social_media`.replace(`{${"code"}}`, encodeURIComponent(String(requestParameters['code']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ApiPeopleCodeSocialMediaGet200ResponseFromJSON(jsonValue));
    }

    /**
     * This endpoint returns all social media associated with a specific person code.
     * Get social media accounts by person code
     */
    async apiPeopleCodeSocialMediaGet(requestParameters: ApiPeopleCodeSocialMediaGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ApiPeopleCodeSocialMediaGet200Response> {
        const response = await this.apiPeopleCodeSocialMediaGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * This endpoint returns an array of first letters of last names for which there are records present in the people table.
     * Get first letters of last names
     */
    async apiPeopleFirstLettersGetRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ApiPeopleFirstLettersGet200Response>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/people/first_letters`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ApiPeopleFirstLettersGet200ResponseFromJSON(jsonValue));
    }

    /**
     * This endpoint returns an array of first letters of last names for which there are records present in the people table.
     * Get first letters of last names
     */
    async apiPeopleFirstLettersGet(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ApiPeopleFirstLettersGet200Response> {
        const response = await this.apiPeopleFirstLettersGetRaw(initOverrides);
        return await response.value();
    }

    /**
     * This endpoint returns a list of people whose last names start with the given search query.
     * Get people by last name starting with a search query
     */
    async apiPeopleGetRaw(requestParameters: ApiPeopleGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ApiPeopleGet200Response>> {
        const queryParameters: any = {};

        if (requestParameters['search'] != null) {
            queryParameters['search'] = requestParameters['search'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/people`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ApiPeopleGet200ResponseFromJSON(jsonValue));
    }

    /**
     * This endpoint returns a list of people whose last names start with the given search query.
     * Get people by last name starting with a search query
     */
    async apiPeopleGet(requestParameters: ApiPeopleGetRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ApiPeopleGet200Response> {
        const response = await this.apiPeopleGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * This endpoint registers a post with the specified location for the authenticated user.
     * Register a post
     */
    async apiPostPostRaw(requestParameters: ApiPostPostOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ApiPostPost200Response>> {
        if (requestParameters['apiPostPostRequest'] == null) {
            throw new runtime.RequiredError(
                'apiPostPostRequest',
                'Required parameter "apiPostPostRequest" was null or undefined when calling apiPostPost().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/post`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ApiPostPostRequestToJSON(requestParameters['apiPostPostRequest']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ApiPostPost200ResponseFromJSON(jsonValue));
    }

    /**
     * This endpoint registers a post with the specified location for the authenticated user.
     * Register a post
     */
    async apiPostPost(requestParameters: ApiPostPostOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ApiPostPost200Response> {
        const response = await this.apiPostPostRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * This endpoint sends a One-Time Password (OTP) to the specified email address.
     * Send OTP to email
     */
    async apiSendOtpPostRaw(requestParameters: ApiSendOtpPostOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['apiSendOtpPostRequest'] == null) {
            throw new runtime.RequiredError(
                'apiSendOtpPostRequest',
                'Required parameter "apiSendOtpPostRequest" was null or undefined when calling apiSendOtpPost().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/send-otp`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ApiSendOtpPostRequestToJSON(requestParameters['apiSendOtpPostRequest']),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * This endpoint sends a One-Time Password (OTP) to the specified email address.
     * Send OTP to email
     */
    async apiSendOtpPost(requestParameters: ApiSendOtpPostOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.apiSendOtpPostRaw(requestParameters, initOverrides);
    }

    /**
     * This endpoint retrieves the assets associated with the specified tweet ID, along with their signatures.
     * Get signatures for assets of a specific tweet
     */
    async apiTweetsIdSignaturesGetRaw(requestParameters: ApiTweetsIdSignaturesGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<ApiTweetsIdSignaturesGet200ResponseInner>>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling apiTweetsIdSignaturesGet().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/tweets/{id}/signatures`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ApiTweetsIdSignaturesGet200ResponseInnerFromJSON));
    }

    /**
     * This endpoint retrieves the assets associated with the specified tweet ID, along with their signatures.
     * Get signatures for assets of a specific tweet
     */
    async apiTweetsIdSignaturesGet(requestParameters: ApiTweetsIdSignaturesGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<ApiTweetsIdSignaturesGet200ResponseInner>> {
        const response = await this.apiTweetsIdSignaturesGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * This endpoint verifies the provided OTP for the specified email address and returns a JSON Web Token (JWT) if successful.
     * Verify OTP and return JWT
     */
    async apiVerifyOtpPostRaw(requestParameters: ApiVerifyOtpPostOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ApiVerifyOtpPost200Response>> {
        if (requestParameters['apiVerifyOtpPostRequest'] == null) {
            throw new runtime.RequiredError(
                'apiVerifyOtpPostRequest',
                'Required parameter "apiVerifyOtpPostRequest" was null or undefined when calling apiVerifyOtpPost().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/verify-otp`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ApiVerifyOtpPostRequestToJSON(requestParameters['apiVerifyOtpPostRequest']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ApiVerifyOtpPost200ResponseFromJSON(jsonValue));
    }

    /**
     * This endpoint verifies the provided OTP for the specified email address and returns a JSON Web Token (JWT) if successful.
     * Verify OTP and return JWT
     */
    async apiVerifyOtpPost(requestParameters: ApiVerifyOtpPostOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ApiVerifyOtpPost200Response> {
        const response = await this.apiVerifyOtpPostRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * This endpoint registers a YouTube video with the specified parameters for the authenticated user.
     * Register a YouTube video
     */
    async apiYoutubePostRaw(requestParameters: ApiYoutubePostOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ApiYoutubePost200Response>> {
        if (requestParameters['apiYoutubePostRequest'] == null) {
            throw new runtime.RequiredError(
                'apiYoutubePostRequest',
                'Required parameter "apiYoutubePostRequest" was null or undefined when calling apiYoutubePost().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/youtube`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ApiYoutubePostRequestToJSON(requestParameters['apiYoutubePostRequest']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ApiYoutubePost200ResponseFromJSON(jsonValue));
    }

    /**
     * This endpoint registers a YouTube video with the specified parameters for the authenticated user.
     * Register a YouTube video
     */
    async apiYoutubePost(requestParameters: ApiYoutubePostOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ApiYoutubePost200Response> {
        const response = await this.apiYoutubePostRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * This endpoint verifies the provided JWT.
     * Verify JWT
     */
    async authVerifyTokenGetRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<AuthVerifyTokenGet200Response>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/auth/verify-token`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => AuthVerifyTokenGet200ResponseFromJSON(jsonValue));
    }

    /**
     * This endpoint verifies the provided JWT.
     * Verify JWT
     */
    async authVerifyTokenGet(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<AuthVerifyTokenGet200Response> {
        const response = await this.authVerifyTokenGetRaw(initOverrides);
        return await response.value();
    }

}
