/* tslint:disable */
/* eslint-disable */
/**
 * API Documentation
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { ApiPeopleCodeGet200ResponsePerson } from './ApiPeopleCodeGet200ResponsePerson';
import {
    ApiPeopleCodeGet200ResponsePersonFromJSON,
    ApiPeopleCodeGet200ResponsePersonFromJSONTyped,
    ApiPeopleCodeGet200ResponsePersonToJSON,
    ApiPeopleCodeGet200ResponsePersonToJSONTyped,
} from './ApiPeopleCodeGet200ResponsePerson';

/**
 * 
 * @export
 * @interface ApiPeopleCodeGet200Response
 */
export interface ApiPeopleCodeGet200Response {
    /**
     * 
     * @type {ApiPeopleCodeGet200ResponsePerson}
     * @memberof ApiPeopleCodeGet200Response
     */
    person?: ApiPeopleCodeGet200ResponsePerson;
}

/**
 * Check if a given object implements the ApiPeopleCodeGet200Response interface.
 */
export function instanceOfApiPeopleCodeGet200Response(value: object): value is ApiPeopleCodeGet200Response {
    return true;
}

export function ApiPeopleCodeGet200ResponseFromJSON(json: any): ApiPeopleCodeGet200Response {
    return ApiPeopleCodeGet200ResponseFromJSONTyped(json, false);
}

export function ApiPeopleCodeGet200ResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): ApiPeopleCodeGet200Response {
    if (json == null) {
        return json;
    }
    return {
        
        'person': json['person'] == null ? undefined : ApiPeopleCodeGet200ResponsePersonFromJSON(json['person']),
    };
}

  export function ApiPeopleCodeGet200ResponseToJSON(json: any): ApiPeopleCodeGet200Response {
      return ApiPeopleCodeGet200ResponseToJSONTyped(json, false);
  }

  export function ApiPeopleCodeGet200ResponseToJSONTyped(value?: ApiPeopleCodeGet200Response | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'person': ApiPeopleCodeGet200ResponsePersonToJSON(value['person']),
    };
}

