/* tslint:disable */
/* eslint-disable */
/**
 * API Documentation
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ApiPeopleCodeGet200ResponsePerson
 */
export interface ApiPeopleCodeGet200ResponsePerson {
    /**
     * 
     * @type {string}
     * @memberof ApiPeopleCodeGet200ResponsePerson
     */
    authorCode?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiPeopleCodeGet200ResponsePerson
     */
    lastName?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiPeopleCodeGet200ResponsePerson
     */
    firstName?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiPeopleCodeGet200ResponsePerson
     */
    vanityTag?: string;
    /**
     * 
     * @type {number}
     * @memberof ApiPeopleCodeGet200ResponsePerson
     */
    followersCount?: number;
}

/**
 * Check if a given object implements the ApiPeopleCodeGet200ResponsePerson interface.
 */
export function instanceOfApiPeopleCodeGet200ResponsePerson(value: object): value is ApiPeopleCodeGet200ResponsePerson {
    return true;
}

export function ApiPeopleCodeGet200ResponsePersonFromJSON(json: any): ApiPeopleCodeGet200ResponsePerson {
    return ApiPeopleCodeGet200ResponsePersonFromJSONTyped(json, false);
}

export function ApiPeopleCodeGet200ResponsePersonFromJSONTyped(json: any, ignoreDiscriminator: boolean): ApiPeopleCodeGet200ResponsePerson {
    if (json == null) {
        return json;
    }
    return {
        
        'authorCode': json['author_code'] == null ? undefined : json['author_code'],
        'lastName': json['last_name'] == null ? undefined : json['last_name'],
        'firstName': json['first_name'] == null ? undefined : json['first_name'],
        'vanityTag': json['vanity_tag'] == null ? undefined : json['vanity_tag'],
        'followersCount': json['followers_count'] == null ? undefined : json['followers_count'],
    };
}

  export function ApiPeopleCodeGet200ResponsePersonToJSON(json: any): ApiPeopleCodeGet200ResponsePerson {
      return ApiPeopleCodeGet200ResponsePersonToJSONTyped(json, false);
  }

  export function ApiPeopleCodeGet200ResponsePersonToJSONTyped(value?: ApiPeopleCodeGet200ResponsePerson | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'author_code': value['authorCode'],
        'last_name': value['lastName'],
        'first_name': value['firstName'],
        'vanity_tag': value['vanityTag'],
        'followers_count': value['followersCount'],
    };
}

