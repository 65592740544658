/* tslint:disable */
/* eslint-disable */
/**
 * API Documentation
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ApiAssetsHashPinPostRequest
 */
export interface ApiAssetsHashPinPostRequest {
    /**
     * Whether to pin (true) or unpin (false) the asset
     * @type {boolean}
     * @memberof ApiAssetsHashPinPostRequest
     */
    pin: boolean;
}

/**
 * Check if a given object implements the ApiAssetsHashPinPostRequest interface.
 */
export function instanceOfApiAssetsHashPinPostRequest(value: object): value is ApiAssetsHashPinPostRequest {
    if (!('pin' in value) || value['pin'] === undefined) return false;
    return true;
}

export function ApiAssetsHashPinPostRequestFromJSON(json: any): ApiAssetsHashPinPostRequest {
    return ApiAssetsHashPinPostRequestFromJSONTyped(json, false);
}

export function ApiAssetsHashPinPostRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): ApiAssetsHashPinPostRequest {
    if (json == null) {
        return json;
    }
    return {
        
        'pin': json['pin'],
    };
}

  export function ApiAssetsHashPinPostRequestToJSON(json: any): ApiAssetsHashPinPostRequest {
      return ApiAssetsHashPinPostRequestToJSONTyped(json, false);
  }

  export function ApiAssetsHashPinPostRequestToJSONTyped(value?: ApiAssetsHashPinPostRequest | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'pin': value['pin'],
    };
}

